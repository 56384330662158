import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import UploadPage from './pages/UploadPage';  // Assuming you create this or another page
import Navbar from './components/Navbar';
import LoginRegisterPopup from './components/LoginRegisterPopup';
import AdorableInteractions from "./pages/AdorableInteractions";

const App = () => {
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [user, setUser] = useState(null);

    // Check if the user is logged in on app load
    useEffect(() => {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const accountType = localStorage.getItem('accountType');

        if (token && username && accountType) {
            setUser({ token, username, accountType });
        }
    }, []);

    const handleLoginSuccess = (userData) => {
        setUser(userData);
    };

    const handleLogout = () => {
        localStorage.clear(); // Clear cached user data
        setUser(null);
    };

    return (
        <Router>
            <Navbar user={user} onLogin={() => setIsLoginOpen(true)} onLogout={handleLogout} />
            <LoginRegisterPopup
                isOpen={isLoginOpen}
                onClose={() => setIsLoginOpen(false)}
                onLoginSuccess={handleLoginSuccess}
            />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/upload" element={<UploadPage />} />
                <Route path="/adorable" element={<AdorableInteractions />} />
            </Routes>
        </Router>
    );
};

export default App;
