import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, Grid, Input, Text, Image, useColorMode } from '@chakra-ui/react';
import CatDetailPopup from '../components/CatDetailPopup'; // Import the CatDetailPopup component

const BASE_URL = 'https://catbase.siea.dev/files/';

const HomePage = () => {
    const [cats, setCats] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedCat, setSelectedCat] = useState(null); // State to hold selected cat
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State for popup visibility
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const { colorMode } = useColorMode(); // For dark mode toggle

    const fetchCats = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/cat/list?page=${page}&size=20&search=${searchTerm}`);
            if (!response.ok) throw new Error(`Error: ${response.status} - ${response.statusText}`);

            const data = await response.json();
            setCats(data.cats || []);
            setTotalPages(data.totalPages || 1);
        } catch (error) {
            console.error('Error fetching cats:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCats();
    }, [page, searchTerm]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setPage(1);
    };

    const handleNextPage = () => page < totalPages && setPage((prev) => prev + 1);
    const handlePreviousPage = () => page > 1 && setPage((prev) => prev - 1);

    const formatFilePath = (filePath) => !filePath ? 'https://via.placeholder.com/150' : `${BASE_URL}${filePath.replace(/\\/g, '/')}`;

    const openCatDetail = (cat) => {
        setSelectedCat(cat);
        setIsPopupOpen(true);
    };

    const closeCatDetail = () => {
        setSelectedCat(null);
        setIsPopupOpen(false);
    };

    return (
        <Box
            bgGradient={colorMode === 'dark' ? 'linear(to-br, #111b27, #1d1525)' : 'linear(to-br, #b7d1de, #d5c4e7)'}
            minHeight="100vh"
            color={colorMode === 'dark' ? 'gray.200' : 'gray.800'}
            p={4} // Add padding for better spacing
        >
            <br />
            <br />
            <br />
            <Flex justify="center" my={6}>
                <Input
                    placeholder="Search for cats..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    width="300px"
                    bg={colorMode === 'dark' ? 'gray.700' : 'white'}
                    color={colorMode === 'dark' ? 'white' : 'black'}
                    borderColor={colorMode === 'dark' ? 'gray.600' : 'gray.300'}
                />
            </Flex>

            {/* Cat Grid */}
            {isLoading ? (
                <Text textAlign="center">Loading cats...</Text>
            ) : (
                <Grid
                    templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                    gap={6}
                    maxWidth="1200px" // Set a max width to the grid
                    mx="auto" // Center the grid
                    padding="20px"
                >
                    {cats.length > 0 ? (
                        cats.map((cat) => (
                            <Box
                                key={cat.id}
                                borderWidth="1px"
                                borderRadius="lg"
                                overflow="hidden"
                                bg={colorMode === 'dark' ? 'gray.700' : 'white'}
                                shadow="md"
                                transition="all 0.2s"
                                _hover={{ shadow: 'lg', transform: 'scale(1.05)' }}
                                onClick={() => openCatDetail(cat)} // Open popup on click
                            >
                                <Image
                                    src={formatFilePath(cat.filePath)}
                                    alt={cat.name}
                                    boxSize="200px"
                                    objectFit="cover"
                                    mx="auto"
                                    my="4"
                                />
                                <Box p="4">
                                    <Text fontWeight="bold" fontSize="lg" textAlign="center">
                                        {cat.name}
                                    </Text>
                                    <Text fontSize="sm" textAlign="center">
                                        By {cat.authorUsername || 'Unknown'}
                                    </Text>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Text textAlign="center">No cats found</Text>
                    )}
                </Grid>
            )}

            {/* Pagination Buttons */}
            <Flex justify="center" my={6}>
                <Button onClick={handlePreviousPage} disabled={page === 1} variant="outline" mx={2}>
                    Previous
                </Button>
                <Text mx={4} alignSelf="center">
                    Page {page} of {totalPages}
                </Text>
                <Button onClick={handleNextPage} disabled={page === totalPages} variant="outline" mx={2}>
                    Next
                </Button>
            </Flex>

            {/* Cat Detail Popup */}
            <CatDetailPopup cat={selectedCat} isOpen={isPopupOpen} onClose={closeCatDetail} />
        </Box>
    );
};

export default HomePage;
