import React, { useState } from 'react';
import {
    Box, Button, Input, Flex, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useColorModeValue,
} from '@chakra-ui/react';

const LoginRegisterPopup = ({ isOpen, onClose, onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoginMode, setIsLoginMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL; // Added

    const handleLoginOrRegister = async () => {
        setIsLoading(true);
        const endpoint = isLoginMode ? `${apiBaseUrl}/account/login` : `${apiBaseUrl}/api/account/register`; // Updated
        const body = isLoginMode ? { email, password } : { email, username, password };

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.token);
                localStorage.setItem('username', data.username);
                localStorage.setItem('accountType', data.accountType);

                onLoginSuccess(data);
                onClose();
            } else if (response.status === 409) {
                toast({
                    title: 'Error',
                    description: 'Email or username already exists.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (response.status === 401) {
                toast({
                    title: 'Error',
                    description: 'Invalid credentials.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Error',
                    description: 'An unexpected error occurred.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to communicate with the server.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg={useColorModeValue('white', 'gray.800')} color={useColorModeValue('black', 'white')}>
                <ModalHeader>{isLoginMode ? 'Login' : 'Register'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex direction="column" gap="4">
                        <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        {!isLoginMode && (
                            <Input placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        )}
                        <Input placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </Flex>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="teal" onClick={handleLoginOrRegister} isLoading={isLoading}>
                        {isLoginMode ? 'Login' : 'Register'}
                    </Button>
                    <Button variant="ghost" onClick={() => setIsLoginMode(!isLoginMode)} ml={3}>
                        {isLoginMode ? 'Create an account' : 'Back to login'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default LoginRegisterPopup;