import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Image,
    Flex,
    Box,
    Spinner,
} from '@chakra-ui/react';

const AdorableInteractions = ({ isOpen, onClose }) => {
    const [catImage, setCatImage] = React.useState('');
    const [loading, setLoading] = React.useState(true); // To manage loading state

    // Fetch a random cat image when the component opens
    React.useEffect(() => {
        if (isOpen) {
            setLoading(true); // Set loading to true when fetching
            fetch('https://api.thecatapi.com/v1/images/search')
                .then(response => response.json())
                .then(data => {
                    setCatImage(data[0].url);
                    setLoading(false);
                });
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md"> {/* Set size to "md" for a medium fixed size */}
            <ModalOverlay />
            <ModalContent maxW="400px" maxH="400px"> {/* Set a fixed max width and height */}
                <ModalHeader>Adorable Interaction</ModalHeader>
                <ModalCloseButton />
                <ModalBody
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="300px"
                    overflow="hidden"
                >
                    {loading ? (
                        <Spinner size="lg" />
                    ) : (
                        catImage && (
                            <Flex
                                width="100%"
                                height="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Image
                                    src={catImage}
                                    alt="Adorable Interaction"
                                    maxW="100%"
                                    maxH="100%"
                                    objectFit="contain"
                                />
                            </Flex>
                        )
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AdorableInteractions;