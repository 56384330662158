// src/pages/AdorableInteractions.js
import React, { useState } from 'react';
import {
    Box,
    Input,
    Button,
    Image,
    Text,
    Flex,
    VStack,
    Heading,
} from '@chakra-ui/react';

// Sample cat images
const catImages = [
    'https://cdn2.thecatapi.com/images/c1fGvl5E7.jpg',
    'https://cdn2.thecatapi.com/images/c1fGvxf5f.jpg',
    'https://cdn2.thecatapi.com/images/c1fG5l5V7.jpg',
    'https://cdn2.thecatapi.com/images/c1fNf5lc7.jpg',
    'https://cdn2.thecatapi.com/images/c1fA3gqN7.jpg',
];

const AdorableInteractions = () => {
    const [userInput, setUserInput] = useState('');
    const [responses, setResponses] = useState([]);

    const handleSendMessage = () => {
        if (userInput.trim()) {
            // Generate a random cat image
            const randomCatImage = catImages[Math.floor(Math.random() * catImages.length)];
            // Append the new response to the existing responses
            setResponses([...responses, { message: userInput, image: randomCatImage }]);
            setUserInput(''); // Clear input after sending
        }
    };

    return (
        <Box p={4} maxWidth="600px" mx="auto">
            <Heading mb={4} textAlign="center">Adorable Interactions</Heading>
            <Box height="400px" overflowY="auto" border="1px" borderColor="gray.200" borderRadius="md" p={4}>
                {responses.map((response, index) => (
                    <Box key={index} mb={4}>
                        <Text fontWeight="bold">You:</Text>
                        <Text>{response.message}</Text>
                        <Image src={response.image} alt="Cat response" borderRadius="md" mt={2} />
                    </Box>
                ))}
            </Box>
            <Flex mt={4}>
                <Input
                    placeholder="Type your message..."
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSendMessage();
                        }
                    }}
                />
                <Button ml={2} onClick={handleSendMessage} colorScheme="blue">
                    Send
                </Button>
            </Flex>
        </Box>
    );
};

export default AdorableInteractions;