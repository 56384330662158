import React from 'react';
import { Box, Button, Text, Image, CloseButton } from '@chakra-ui/react';

const CatDetailPopup = ({ cat, isOpen, onClose }) => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = cat.filePath;

        const fileExtension = cat.filePath.split('.').pop();
        link.download = `${cat.name}.${fileExtension}`; // Use the extracted extension
        link.click();
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(cat.filePath);
        alert('Cat image path copied to clipboard!');
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!cat) return null;

    return (
        isOpen && (
            <Box
                position="fixed"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bg="rgba(0, 0, 0, 0.6)"
                zIndex="1000"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={handleOverlayClick}
            >
                <Box
                    bg="white"
                    p={6}
                    borderRadius="md"
                    shadow="md"
                    maxWidth="500px"
                    textAlign="center"
                    position="relative"
                >
                    <CloseButton
                        position="absolute"
                        top={2}
                        right={2}
                        onClick={onClose}
                    />
                    <Text fontWeight="bold" fontSize="xl" mb={4}>
                        {cat.name}
                    </Text>
                    <Image
                        src={cat.filePath}
                        alt={cat.name}
                        boxSize="300px"
                        objectFit="cover"
                        mb={4}
                    />
                    <Text mb={2}>By {cat.authorUsername || 'Unknown'}</Text>
                    <Text mb={4}>{cat.description || 'No description available.'}</Text>
                    <Button onClick={handleDownload} colorScheme="teal" mr={2}>
                        Download
                    </Button>
                    <Button onClick={handleCopyLink} colorScheme="blue">
                        Copy Link
                    </Button>
                </Box>
            </Box>
        )
    );
};

export default CatDetailPopup;