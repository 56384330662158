import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    useToast,
    Wrap,
    Tag,
    TagCloseButton,
    TagLabel,
    Box,
    Text,
} from '@chakra-ui/react';

const UploadPopup = ({ isOpen, onClose }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(''); // State to store selected file name
    const toast = useToast();

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // Check if a file was selected
        if (selectedFile) {
            // Reset fields if a new file is selected
            setName('');
            setDescription('');
            setTags([]);
            setFile(selectedFile); // Set the new file
            setFileName(selectedFile.name); // Update the file name state
        }
    };

    const handleTagChange = (event) => {
        const value = event.target.value;
        if (event.key === 'Enter' && value.trim()) {
            setTags((prevTags) => [...prevTags, value.trim()]);
            event.target.value = ''; // Clear input after adding
        }
    };

    const handleTagDelete = (tagToDelete) => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    const handleUpload = async () => {
        if (!name || !file) { // Only require name and file
            toast({
                title: "Error",
                description: "Please fill in the name field and select a file.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description); // Optional
        formData.append('tags', JSON.stringify(tags));
        formData.append('token', localStorage.getItem('token'));
        formData.append('file', file);

        try {
            const response = await fetch('/cat/upload', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            toast({
                title: "Upload Successful",
                description: "Your cat has been uploaded successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            console.error('Error uploading cat:', error);
            toast({
                title: "Upload Failed",
                description: "There was an error uploading your cat.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        if (event.dataTransfer.files.length) {
            const droppedFile = event.dataTransfer.files[0];
            // Reset fields if a new file is dropped
            setName('');
            setDescription('');
            setTags([]);
            setFile(droppedFile);
            setFileName(droppedFile.name);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload Cat</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {file && (
                        <Box mb={4} textAlign="center" display="flex" justifyContent="center">
                            {file.type.startsWith('image/') ? (
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt="Cat Preview"
                                    style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }}
                                />
                            ) : (
                                <video
                                    src={URL.createObjectURL(file)}
                                    controls
                                    style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }}
                                />
                            )}
                        </Box>
                    )}
                    <FormControl isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input
                            placeholder="Enter cat name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Description</FormLabel>
                        <Textarea
                            placeholder="Enter cat description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Tags</FormLabel>
                        <Input
                            placeholder="Press Enter to add a tag"
                            onKeyDown={handleTagChange}
                        />
                        <Wrap mt={2}>
                            {tags.map((tag) => (
                                <Tag size="md" key={tag} variant="solid" colorScheme="blue">
                                    <TagLabel>{tag}</TagLabel>
                                    <TagCloseButton onClick={() => handleTagDelete(tag)} />
                                </Tag>
                            ))}
                        </Wrap>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Upload Image/Video</FormLabel>
                        <Box
                            border='2px dashed'
                            borderColor='gray.300'
                            borderRadius='md'
                            padding='20px'
                            textAlign='center'
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            sx={{
                                cursor: 'pointer',
                                transition: 'border-color 0.3s',
                                _hover: { borderColor: 'blue.300' },
                            }}
                        >
                            <Text fontSize="lg" color="gray.500">
                                Drag & Drop your image or video here, or click to select
                            </Text>
                            <Input
                                type="file"
                                accept="image/*,video/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }} // Hide the default input
                                onClick={(e) => {
                                    // Prevent clearing of file on cancel
                                    e.stopPropagation();
                                }}
                            />
                            <Button
                                onClick={() => document.querySelector('input[type="file"]').click()} // Trigger file input click
                                mt={2}
                                colorScheme="blue"
                            >
                                Select File
                            </Button>
                        </Box>
                        {/* Display the selected file name below the upload area */}
                        {fileName && (
                            <Text mt={2} textAlign="center" fontWeight="bold" color="blue.500">
                                Selected File: {fileName}
                            </Text>
                        )}
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleUpload}>
                        Upload
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UploadPopup;