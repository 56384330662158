import React, { useState } from 'react';
import {
    Box, Flex, Button, IconButton, Avatar, useColorMode, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Text, useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
    MoonIcon,
    SunIcon,
    ChevronDownIcon,
    ArrowForwardIcon,
    PlusSquareIcon,
    ChatIcon,
    UpDownIcon
} from '@chakra-ui/icons';
import LoginRegisterPopup from './LoginRegisterPopup';
import AdorableInteractions from './AdorableInteractions';
import UploadPopup from './UploadPopup';

const Navbar = () => {
    const navigate = useNavigate();
    const { colorMode, toggleColorMode } = useColorMode();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isUploadOpen, onOpen: onUploadOpen, onClose: onUploadClose } = useDisclosure(); // For UploadPopup
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const { isOpen: isAdorableOpen, onOpen: onAdorableOpen, onClose: onAdorableClose } = useDisclosure();

    const handleLoginSuccess = (userData) => {
        setIsLoggedIn(true);
        setUserProfile(userData);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setUserProfile(null);
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100%"
            zIndex="10"
            bg={colorMode === 'dark' ? 'rgba(26, 27, 75, 0.2)' : 'rgba(255, 240, 245, 0.2)'}
            backdropFilter="blur(10px)"
            boxShadow="sm"
            p={4}
        >
            <Flex justify="space-between" align="center" maxW="1200px" mx="auto">
                {/* Left: Brand Name */}
                <Flex align="center">
                    <Text fontSize="xl" fontWeight="bold" letterSpacing="wider">Catbase</Text>
                    <Text fontSize="llg" fontWeight="bold" ml={0} mt={1.5} color="pink.300">.siea.dev</Text>
                </Flex>

                {/* Right: Navigation, Dark Mode Toggle, Upload, and Profile/Login */}
                <Flex align="center">
                    <Button
                        colorScheme="white"
                        variant="ghost"
                        onClick={() => navigate('/')}
                        mr={4}
                        backgroundColor="transparent"
                        leftIcon={<UpDownIcon />}
                    >
                        Home
                    </Button>
                    <Button
                        colorScheme="white"
                        variant="ghost"
                        onClick={onUploadOpen}
                        mr={4}
                        backgroundColor="transparent"
                        leftIcon={<PlusSquareIcon />}
                    >
                        Upload
                    </Button>
                    <Button
                        colorScheme="pink"
                        variant="ghost"
                        onClick={onAdorableOpen}
                        mr={4}
                        backgroundColor="transparent"
                        leftIcon={<ChatIcon />}
                    >
                        AI
                    </Button>

                    {/* Dark Mode Toggle */}
                    <IconButton
                        aria-label="Toggle Dark Mode"
                        icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
                        onClick={toggleColorMode}
                        variant="ghost"
                        mr={4}
                        backgroundColor="transparent"
                    />

                    {/* Profile Dropdown or Login Button */}
                    {isLoggedIn && userProfile ? (
                        <Menu>
                            <MenuButton as={Button} variant="ghost" p={0} backgroundColor="transparent">
                                <Avatar size="sm" name={userProfile.username} />
                            </MenuButton>
                            <MenuList
                                bg={colorMode === 'dark' ? 'gray.700' : 'white'}
                                border="none"
                                color={colorMode === 'dark' ? 'white' : 'gray.800'}
                            >
                                <Box p={4} textAlign="center">
                                    <Avatar size="lg" name={userProfile.username} />
                                    <Text mt={2} fontWeight="bold">{userProfile.username}</Text>
                                    <Text fontSize="sm" color="teal.300">{userProfile.accountType}</Text>
                                </Box>
                                <MenuDivider />
                                <MenuItem icon={<ChevronDownIcon />} onClick={() => alert('Go to Dashboard')}>Dashboard</MenuItem>
                                <MenuItem icon={<ChevronDownIcon />} onClick={() => alert('Manage Account')}>Manage Account</MenuItem>
                                <MenuDivider />
                                <MenuItem icon={<ArrowForwardIcon />} onClick={handleLogout}>Log Out</MenuItem>
                            </MenuList>
                        </Menu>
                    ) : (
                        <Button
                            colorScheme="orange"
                            variant="outline"
                            onClick={onOpen}
                            backgroundColor="transparent"
                        >
                            Login
                        </Button>
                    )}
                </Flex>
            </Flex>

            {/* Login/Registration Popup */}
            <LoginRegisterPopup isOpen={isOpen} onClose={onClose} onLoginSuccess={handleLoginSuccess} />

            {/* Adorable Interactions Popup */}
            <AdorableInteractions isOpen={isAdorableOpen} onClose={onAdorableClose} />

            {/* Upload Popup */}
            <UploadPopup isOpen={isUploadOpen} onClose={onUploadClose} />
        </Box>
    );
};

export default Navbar;